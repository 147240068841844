.contact-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: var(--spacing-xxs) var(--spacing-lg);

  .contact-form-header {
    grid-column: 1 / 10;
  }

  .contact-phone-custom-message {
    grid-column: 1 / 6;
  }

  .contact-email-custom-message {
    grid-column: 6 / 11;
  }

  .contact-form-prefix {
    grid-column: 1 / 3;
    & ~ .contact-form-mobile {
      grid-column: 3 / 6;
    }
  }

  .contact-form-mobile {
    grid-column: 1 / 6;
  }

  .contact-form-email {
    grid-column: 6 / 11;
  }
}
