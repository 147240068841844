@import 'assets/styles/zIndexVariables';

.header-container {
  z-index: $headerZIndex;

  &.header-container-transparent {
    background-color: transparent;
    box-shadow: none;
  }
}
