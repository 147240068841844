$consentDisabledLeftMargin: 48px;

.consent-checkbox-label-wrapper {
  .consent-checkbox-label-link {
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
  }
}
.consent-disabled-cbp-consent {
  max-width: 340px;
  margin-bottom: var(--spacing-s);
  margin-left: $consentDisabledLeftMargin;
}
