.check-in-confirmation-form-wrapper {
  display: flex;
  flex-direction: column;

  .check-in-confirmation-form-subsection {
    flex: 1;

    &:first-child:not(:only-child) {
      margin-bottom: var(--spacing-xxlg);
    }
  }
}
