$leftAdjustmentMargin: -10px;

.terms-and-conditions-component {
  .terms-and-conditions-title {
    margin-bottom: var(--spacing-sm);
  }

  .terms-and-conditions-consents {
    margin-top: var(--spacing-lg);
    margin-left: $leftAdjustmentMargin;

    .terms-and-conditions-link {
      text-decoration: underline;
    }
  }
}
