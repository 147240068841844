$leftAdjustmentMargin: -10px;

.folio-style-type-selection-wrapper {
  .folio-style-type-selection-header {
    white-space: pre-wrap;
  }

  .folio-style-type-selection-options {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
    margin-top: var(--spacing-md);
    margin-left: $leftAdjustmentMargin;

    &.folio-style-type-selection-options-column {
      flex-direction: column;
    }
  }
}
