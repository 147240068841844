@import 'assets/styles/sizes';

.make-keys-header-wrapper {
  .day-use-icon {
    width: $validationIconSize;
    height: $validationIconSize;
    padding: var(--spacing-xxs);
    color: var(--white-color);
    cursor: pointer;
    background-color: var(--warning-state-color);
    border-radius: 50%;
  }
}
