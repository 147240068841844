.review-address-modal {
  text-align: center;

  .review-address-subtitle {
    white-space: pre-wrap;
  }

  .review-address-content-wrapper {
    display: grid;
    grid-template-rows: repeat(7, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: var(--spacing-md) var(--spacing-xxlg);
    text-align: left;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
