.checkout-charges-tax-id-section {
  line-height: 1.5;
  text-align: right;

  .checkout-charges-tax-id-section-link {
    color: var(--primary-color);
  }
}
.checkout-charges-tax-id-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkout-charges-tax-id-modal-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
