$notification-bar-height: 50px;
$notification-bar-size: 22px;

.notification-bar-element {
  width: 100%;
  height: $notification-bar-height;
  overflow: hidden;
  color: var(--white-color);

  &.notification-bar-type-error {
    background-color: var(--error-state-color);
  }

  &.notification-bar-type-warn {
    background-color: var(--warning-state-color);
  }

  &.notification-bar-type-success {
    background-color: var(--success-state-color);
  }

  .notification-bar-icon {
    width: $notification-bar-size;
    height: $notification-bar-size;
  }
}
