$largeHeaderMinHeight: 330px;
$horizontalPadding: 93px;
$verticalPadding: 60px;
$imageMaxHeight: 82px;
$description-font-size: 40px;
$headerBackgroundImageBlur: 2px;

.large-header-wrapper {
  display: flex;
  height: $largeHeaderMinHeight;
  min-height: $largeHeaderMinHeight;
  padding: $verticalPadding $horizontalPadding;
  overflow: hidden;
  color: var(--header-font-color);
  background-color: var(--header-background-color);

  &.large-header-wrapper-transparent {
    background-color: transparent;
  }

  .large-header-logo {
    height: $imageMaxHeight;
  }

  .large-header-background-image {
    position: absolute;
    top: -$headerBackgroundImageBlur * 2;
    left: -$headerBackgroundImageBlur * 2;
    width: calc(100% + $headerBackgroundImageBlur * 4);
    height: calc(100% + $headerBackgroundImageBlur * 4);
    filter: blur($headerBackgroundImageBlur);
    background-position: center center;
    background-size: cover;
    opacity: 0.7;
  }

  .large-header-content {
    position: relative;
    z-index: 1;
  }

  .large-header-description {
    &.large-header-description-first-line,
    &.large-header-description-second-line {
      font-size: $description-font-size;
      line-height: 1.3;
    }
  }
}
