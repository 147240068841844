.key-generator-container {
  .key-generator-selector-container {
    min-height: 100%;
  }

  .key-generator-selector-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
