$room-number-size: 40px;

.key-complete-container {
  .room-number {
    font-size: $room-number-size;
  }

  .counter-notification {
    font-size: var(--font-size-sm);
    white-space: pre-line;
  }
}
