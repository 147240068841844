.reservation-header-section {
  background-color: var(--reservation-header-background-color);

  &.reservation-header-section-with-border {
    border-bottom: 1px solid var(--gray-color-1);
  }

  &.reservation-header-transparent {
    background-color: transparent;
  }
}
