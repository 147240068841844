.additional-info-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: var(--spacing-xxs) var(--spacing-lg);

  .additional-info-form-header {
    grid-area: 1 / span 10;
  }

  .additional-info-form-nationality {
    grid-area: 2 / span 5;
  }
}
