@import 'assets/styles/zIndexVariables';

.gss-view-container {
  font-family: var(--main-font);
  color: var(--body-font-color);
  background: var(--body-background-color);

  .gss-view-modals {
    z-index: $localViewsModalsZIndex;
  }
}
