$imageWrapperPadding: 50px;

.check-in-id-scanning-wrapper {
  display: flex;
  height: 100%;
  overflow-x: hidden;

  .check-in-id-scanning-wrapper-with-image {
    padding-right: $imageWrapperPadding;
  }

  .check-in-id-scanning-section {
    flex: 1;
  }

  .check-in-id-scanning-image {
    width: 50%;
    object-fit: cover;
  }
}
