.scanning-document-error-modal {
  text-align: center;

  .error-circular-icon {
    color: var(--primary-color);
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .buttons-wrapper > :only-child {
    margin: auto;
  }
}
