$overlayZindex: 1200;

.overlay-view-container {
  position: absolute;
  z-index: $overlayZindex;
  width: 100%;

  .overlay-view-header,
  .overlay-view-footer {
    z-index: 1;
  }
}
