$scannerPreviewSize: 240px;

.qrscanner-modal-container {
  .qrscanner-scanner-preview-container {
    display: flex;
    width: $scannerPreviewSize;
    height: $scannerPreviewSize;
    min-height: $scannerPreviewSize;
    margin: auto;
  }

  .qrscanner-scanner-preview {
    width: 100%;
    height: 100%;
  }

  .qrscanner-scanner-loader {
    width: 100%;
    margin: auto;
  }

  .hidden {
    display: none;
  }
}
