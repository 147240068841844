.external-link-overlay-body {
  position: relative;
  overflow: hidden;

  .external-link-overlay-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--body-background-color);
  }

  .external-link-overlay-iframe {
    width: 100%;
    height: 100%;
  }
}
