@import 'assets/styles/mediaQueries';

$description-margin-sm: auto 0;
$description-margin-lg: 0 auto;

.route-buttons-wrapper {
  .route-buttons-grid {
    justify-content: space-evenly;
    width: fit-content;
    max-width: 1200px;
    margin: auto;

    @include large-screen {
      width: 100%;
    }

    .route-buttons-description-item {
      margin: $description-margin-sm;

      @include large-screen {
        grid-row: 2;
        margin: $description-margin-lg;
        text-align: center;
      }
    }
  }
}
