.check-name-details-modal {
  text-align: center;

  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: var(--spacing-sm);
    align-items: baseline;
  }

  .content-label {
    text-align: end;
  }

  .content-value {
    text-align: start;
  }

  .buttons-wrapper {
    display: flex;
  }
}
