$secretButtonZIndex: 9999;

.welcome-view-wrapper {
  .property-name {
    color: var(--gray-color-1);
  }

  .welcome-view-secret-button {
    z-index: $secretButtonZIndex;
  }
}
