$step-height: 5px;
$step-radius: 100px;

.stepper-component {
  .steps-graph {
    width: 100%;
  }

  .step-box {
    width: 100%;
    height: $step-height;
    border: 1px solid var(--counter-border-color);
    border-radius: $step-radius;
  }

  .step-box-finished {
    background-color: var(--counter-finished-background-color);
  }

  .step-box-todo {
    background-color: var(--counter-background-color);
  }

  .step-counter {
    color: var(--counter-font-color);
  }
}
