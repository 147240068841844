@import 'assets/styles/zIndexVariables';

.footer-container {
  position: relative;
  z-index: $footerZIndex;

  &.footer-container-transparent {
    background-color: transparent;
  }

  .footer-stepper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
  }
}
