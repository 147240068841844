$icon-size: 18px;

.consent-list-wrapper {
  .consent-icon {
    width: $icon-size;
    margin-right: var(--spacing-md);
    fill: var(--body-font-color);
  }

  .consent-item {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-lg);
    list-style: none;
  }
}
