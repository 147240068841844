.address-form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: var(--spacing-xxs) var(--spacing-lg);

  .address-header {
    grid-area: 1 / span 10;
  }

  .address-addressline {
    grid-area: 2 / span 5;
  }

  .address-code {
    grid-area: 3 / span 2;
  }

  .address-city {
    grid-area: 3 / span 3;
  }

  .address-country {
    grid-area: 3 / span 5;
  }

  .address-state {
    grid-area: 4 / span 5;
  }

  .address-district {
    grid-area: 4 / span 5;
  }
}
