.scanned-data-verification-modal {
  text-align: center;

  .data-wrapper {
    display: flex;
    gap: var(--spacing-md);
    overflow-y: auto;
    text-align: left;
  }

  .data-column {
    display: grid;
    flex: 1;
    gap: var(--spacing-md);
    height: max-content;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
