@import 'assets/styles/zIndexVariables';

.service-modal-magic-button {
  z-index: 1;
}

.connection-modal-component {
  z-index: $connectionModalsZIndex;
}

.global-modals-components {
  z-index: $globalModalsZIndex;
}

.screen-saver-element {
  z-index: $screenSaverZIndex;
}
